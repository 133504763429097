<script setup lang="ts">
const [isShow, toggleSidebar] = useToggle(false);
const { data: appInitData } = useAppInitData();
const { open } = useAppModals();
const { logoutUser } = useLogout();
const route = useRoute();
const isGuest = useIsGuest();
const { sendAdAction } = usePopupAds();
const { handleOpenGame } = useOpenGame(open);
const refCode = useState("refCode");
const { onMessage, onUpdateToken } = useFirebaseNotification();

const isEmailFirstContactAndNotConfirmed = computed(
	() => appInitData.value?.primaryContact === "email" && !appInitData.value?.emailConfirmed
);
const isShowEmailTopline = computed(() => !isGuest.value && isEmailFirstContactAndNotConfirmed.value);

const handleToggleSidebar = () => {
	toggleSidebar();
};
useEvent(["SHOW_VERIFICATION_PHONE"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalPhoneConfirmation", { location: detail?.location });
});

useEvent(["COMPLETE_PROFILE_REWARD"], (event: Event) => {
	const { detail } = event as CustomEvent<{ rewardEntry: boolean; showRewardsModal: boolean }>;
	if (detail?.rewardEntry) {
		window?.$cash?.$router?.push("/");
	}

	if (detail?.showRewardsModal) {
		open("LazyOModalPhoneConfirmation", { confirmPhoneDeposit: !appInitData.value?.phone });
	}
});
useEvent(["LOGOUT_AND_OPEN_AUTH_MODAL"], () => {
	if (window?.$cash?.$store?.getters?.["cash/isOpen"]) {
		window?.$cash?.$router?.push("/");
	}
	window?.$cash?.$store?.dispatch?.("cash/setProfile", null);
	logoutUser();
	open("LazyOModalLogin");
});
useEvent(["OPEN_INVITE_FRIENDS_MODAL"], () => {
	open("LazyOModalPromoInviteFriends");
});

const handleOpenModal = (modal: string) => {
	open(modal as keyof Modals);
};

const headerRef = ref(null);
useResizeObserver(headerRef, (entries) => {
	const entry = entries[0];
	const { height } = entry.contentRect;
	document.body.style.setProperty("--top-height", `${height}px`);
});

if (process.client) {
	watch([() => route.path, () => route.query], ([, newQuery]) => {
		if (appInitData.value?.bingo?.isActive && "welcome-bingo" in newQuery) {
			open("LazyOModalBingoWelcome");
		}
	});
}

onMounted(() => {
	const { refcode, action, social } = route.query;
	if (action || social) {
		dispatchGAEvent({
			event: action === "register" ? "registration" : "login",
			location: "header",
			type: social as string,
			step: "success",
			playerId: appInitData.value?.login || ""
		});
	}
	if (appInitData.value?.bingo?.isActive && "welcome-bingo" in route?.query) {
		open("LazyOModalBingoWelcome");
	}

	if (refcode) {
		refCode.value = refcode;
	}
	pushNotificationsAddEventListener("message", onMessage);
	pushNotificationsAddEventListener("updateToken", onUpdateToken);
});

onUnmounted(() => {
	pushNotificationsRemoveEventListener("message", onMessage);
	pushNotificationsRemoveEventListener("updateToken", onUpdateToken);
});
</script>

<template>
	<div class="layout">
		<ClientOnly>
			<LazyCash v-if="!isGuest" />
			<LazyODynamicAdModal :openGame="handleOpenGame" @send-ad-action="sendAdAction" @open-modal="handleOpenModal" />
			<LazyOCookieControl />
		</ClientOnly>
		<div ref="headerRef" class="header-wrapper">
			<LazyOEmailNotifyTopline v-if="isShowEmailTopline" />
			<LazyOHeader v-if="!isGuest" :class="[{ 'is-logged': !isGuest }]" />
			<LazyOHeaderGuest v-else :class="[{ 'is-logged': !isGuest }]" />
		</div>

		<div class="container" :class="[{ 'is-logged': !isGuest }, { 'is-email-notify': isShowEmailTopline }]">
			<LazyOSidebar :show="isShow" @toggle-sidebar="handleToggleSidebar" />
			<main class="page-content">
				<div class="wrapper">
					<slot />
				</div>
			</main>

			<LazyOFooter />
			<LazyOMobNavigation :isShowSeason="appInitData?.season?.isEnabled" @toggle-sidebar="handleToggleSidebar" />
			<LazyMWheelOfTreasureMiniFixedWheel v-if="appInitData?.depositStreak?.isActive" />
		</div>
	</div>
	<ASvgCollection />
	<LazyMWidgetsFixedSide v-if="!isGuest" :data="appInitData" />
</template>

<style lang="scss" scoped>
.layout {
	min-height: 100dvh;
	display: flex;
	flex-direction: column;

	header {
		display: flex;

		@include media-breakpoint-up(lg) {
			display: none;
			&.is-logged {
				display: flex;
			}
		}
	}

	footer {
		margin-bottom: gutter(8.25);

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
	}
}

.notification-center {
	z-index: 2000;
}

.header-wrapper {
	margin-left: auto;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 4;
}

.container {
	flex: 1;
	margin-top: gutter(7.5);

	&.is-email-notify {
		margin-top: gutter(16.5);
	}

	@media (min-width: 411px) {
		&.is-email-notify {
			margin-top: gutter(14.5);
		}
	}

	@include media-breakpoint-up(lg) {
		margin-top: 0;
		padding: gutter(2) gutter(3) 0;

		&.is-logged {
			margin-top: gutter(10);
		}

		&.is-email-notify {
			margin-top: gutter(16);
		}
	}
}

.page-content {
	position: relative;
	display: block;
	margin-left: 0;

	@include media-breakpoint-up(lg) {
		margin-left: calc(var(--o-sidebar-width) + gutter(3));
	}
}

.wrapper {
	margin: 0 auto;
	max-width: 1136px;
	width: 100%;
}
</style>

<style lang="scss">
.desktop {
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}

.mobile {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
